export const ENTITY = 'FAQ_PAGE';

const initialState = {
    data: [],
    loading: false,
    error: null
}

const faqPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case `GET_${ENTITY}_REQUEST`:
            return {...state, loading: true, error: null}
        case `GET_${ENTITY}_SUCCESS`:
            return {...state, data: action.payload, loading: false, error: null}
        case `GET_${ENTITY}_ERROR`:
            return {...state, loading: false, error: action.payload}
        default:
            return state;
    }
}

export default faqPageReducer;

