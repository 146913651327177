export const ENTITY = 'SETTINGS';

const initialState = {
  sumSubUrl: '',
}

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case `GET_${ENTITY}_REQUEST`:
            return {
                ...state,
                loading: true,
                error: null
            }
        case `GET_${ENTITY}_SUCCESS`:
            return {
                ...state,
                sumSubUrl: action.data.sumSubUrl,
                loading: false,
                error: null
            }
        case `GET_${ENTITY}_ERROR`:
            return {
                ...state,
                loading: true,
                error: null
            }
        default :
            return state;
    }
}

export default settingsReducer;