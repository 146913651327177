export const ENTITY = 'ORDERS_HISTORY';

const initialState = {
  data: [],
  total: 1,
  loading: false,
  meta: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `GET_${ENTITY}_REQUEST`:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case `GET_${ENTITY}_SUCCESS`:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        meta: action.meta,
        loading: false,
      }
    case `GET_${ENTITY}_ERROR`:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return { ...state }
  }
}
export default reducer
