// **  Initial State
export const initialState = {
  userData: {},
  resendSeconds: 40,
  verificationFlag: false,
  isSuspended: false,
  clientFlow: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, isSuspended: false, ...obj }
    case 'UPDATE_VERIFICATION_FLAG':
      return {
        ...state,
        verificationFlag: action.value
      }
    case 'SET_USER_SUSPENDED_FLAG':
      return { ...state, isSuspended: action.value };
    case 'UPDATE_RESEND_TIME':
      return {
        ...state,
        resendSeconds: action.resendSeconds,
      }
    case 'SET_CLIENT_FLOW':
      return {
        ...state,
        clientFlow: action.clientFlow,
      }
    default:
      return state
  }
}

export default authReducer
