export const ENTITY = 'NOTIFICATIONS';

const initialState = {
  data: [],
  loading: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `GET_${ENTITY}_REQUEST`:
      return {
        ...state,
        loading: true,
      }
    case `GET_${ENTITY}_SUCCESS`:
      return {
        ...state,
        data: action.data,
        loading: false,
      }
    case `GET_${ENTITY}_ERROR`:
      return {
        ...state,
        loading: false,
      }
    case 'RESET_NOTIFICATIONS':
      return {
        ...state,
        data: [],
        loading: false,
      }
    default:
      return { ...state }
  }
}
export default reducer
