export const ENTITY = 'PUBLIC_PAGES';
export const ENTITY_BLOCKS = 'PUBLIC_BLOCKS';

const initialState = {
    data: [],
    loading: false,
    error: null,
    blocks: []
}

const publicPagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case `GET_${ENTITY}_REQUEST`:
            return {
                ...state,
                loading: true,
                error: null
            }
        case `GET_${ENTITY}_SUCCESS`:
            return {
                ...state,
                data: action.data,
                loading: false,
                error: null
            }
        case `GET_${ENTITY}_ERROR`:
            return {
                ...state,
                loading: true,
                error: null
            }
        case `GET_${ENTITY_BLOCKS}_REQUEST`:
            return {
                ...state,
                loading: true,
                error: null
            }
        case `GET_${ENTITY_BLOCKS}_SUCCESS`:
            return {
                ...state,
                blocks: action.data,
                loading: false,
                error: null
            }
        case `GET_${ENTITY_BLOCKS}_ERROR`:
            return {
                ...state,
                loading: true,
                error: null
            }

        default :
            return state;
    }
}

export default publicPagesReducer;