export const baseURL = process.env.REACT_APP_API_URL + '/web/v1';
export const basePublicURL = process.env.REACT_APP_API_URL + '/public/v1'
export const csrfURL = process.env.REACT_APP_API_URL + '/sanctum/csrf-cookie';
export const captchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
export const csrfCookie = process.env.REACT_APP_CSRF_COOKIE;
export const sessionAuthMethod = 'session';
export const envLocal = 'local';
export const urlSumSub = process.env.REACT_APP_SUMSUB_URL;
export const fakeRecaptchaKey = process.env.REACT_APP_FAKE_RECAPTCHA_KEY;

export const PAYMENT_FLOWS = {
  CARD: "CARD",
  BANK: "BANK",
  TRANSACTIVE: "TRANSACTIVE",
};

export const STEPS = {
  ORDER_DETAILS: 'STEP_ORDER_DETAILS',
  CRYPTO_WALLET: 'STEP_CRYPTO_WALLET',
  PERSONAL_DETAILS: 'STEP_PERSONAL_DETAILS',
  PLACE_ORDER: 'STEP_PLACE_ORDER',
  ORDER_STATUS: 'STEP_ORDER_STATUS',
  PLACE_ORDER_WAIT_POSTBACK: 'STEP_PLACE_ORDER_WAIT_POSTBACK',
}

export const DEFAULT_PAYMENT_ID = 1;

export const DEFAULTS = {
  PAYMENT_ID: 1,
  PAYMENT_FLOW: PAYMENT_FLOWS.BANK,
  SOURCE_CURRENCY_ID: '2', // EUR
  TARGET_CURRENCY_ID: '179', // BTC
};
