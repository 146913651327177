import { DEFAULTS, STEPS } from "../../../configs/constants"
import { updateOrderInfo } from "./helpers"

// **  Initial State
export const initialState = {
  calculatedOrder: {
    paymentMethodId: DEFAULTS.PAYMENT_ID,
  },
  editedOrder: {
    sourceAmount: '',
    sourceCurrencyId: DEFAULTS.SOURCE_CURRENCY_ID,
    targetCurrencyId: DEFAULTS.TARGET_CURRENCY_ID,
    paymentUuid: '',
    paymentFlow: DEFAULTS.PAYMENT_FLOW,
    canCancel: true,
  },
  placedOrder: {},
  walletAddress: '',
  isOwnAccepted: false,
  isAgreementAccepted: false,
  orderId: '',
  loading: false,
  bankInfo: {},
  step: STEPS.ORDER_DETAILS,
  currentOrderError: null,
  loadingCurrentOrder: true,
  steps: [],
}

const order = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ORDER_STORE_ERROR':
      return {
        ...state,
        loadingCurrentOrder: false,
      }
    case 'SET_ORDER_STORE':
      return {
        ...action.orderStore,
        loadingCurrentOrder: false,
        currentOrderError: null,
      }
    case 'SELECT_PAYMENT_METHOD_ID':
      return {
        ...state,
        calculatedOrder: {
          ...state.calculatedOrder,
          paymentMethodId: action.paymentMethodId,
        }
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading,
      }
    case 'SET_STEP':
      return {
        ...state,
        step: action.step === STEPS.PLACE_ORDER_WAIT_POSTBACK ? STEPS.PLACE_ORDER : action.step,
      }
    case 'CALCULATE_ORDER_REQUEST':
      return {
        ...state,
        loading: true,
      }
    case 'CALCULATE_ORDER_SUCCESS':
      return { 
        ...state,
        calculatedOrder: {
          ...state.calculatedOrder,
          ...action.calculatedOrder,
        },
        loading: false,
      }
    case 'CALCULATE_ORDER_ERROR':
      return { 
        ...state,
        loading: false,
      }
    case 'EDIT_ORDER':
      return updateOrderInfo(state, action.property, action.value);
    case 'SET_USER_REFERENCE':
      return updateOrderInfo(state, 'paymentUuid', action.value);
    case 'EDIT_WALLET':
      return {
        ...state,
        walletAddress: action.walletAddress,
      }
    case 'TOGGLE_OWN':
      return {
        ...state,
        isOwnAccepted: action.flag,
      }
    case 'TOGGLE_AGREEMENT':
      return {
        ...state,
        isAgreementAccepted: action.flag,
      }
    case 'UPDATE_BANK_INFO':
      return {
        ...state,
        bankInfo: action.bankInfo,
      }
    case 'EDIT_ORDER_ID':
      return {
        ...state,
        editedOrder: {
          ...state.editedOrder,
          paymentFlow: action.paymentFlow,
          canCancel: action.canCancel,
        },
        orderId: action.orderId,
      }
    case 'RESET_ORDER':
      return {
        ...initialState,
        loadingCurrentOrder: false,
        bankInfo: {
          ...state.bankInfo,
        },
        editedOrder: {
          sourceAmount: '',
          sourceCurrencyId: DEFAULTS.SOURCE_CURRENCY_ID,
          targetCurrencyId: DEFAULTS.TARGET_CURRENCY_ID,
          paymentUuid: state.editedOrder.paymentUuid,
        },
      };
    case 'PLACE_ORDER':
      return {
        ...state,
        placedOrder: action.placedOrder,
      }
    case 'GET_CURRENT_ORDER_ERROR':
      return {
        ...state,
        currentOrderError: action.error,
      }
    case 'SET_ORDER_STEPS':
      return {
        ...state,
        steps: action.steps.filter(step => step !== STEPS.PLACE_ORDER_WAIT_POSTBACK),
      }
    default:
      return state
  }
}

export default order
