import { useState, createContext, useEffect } from 'react'

import { IntlProvider } from 'react-intl'

import messagesEn from '@src/assets/data/locales/en.json'
import messagesRu from '@src/assets/data/locales/ru.json'

import LngStorage from '../../@core/services/storage/lng';
import http from '../../@core/services/http/public';

const menuMessages = {
  en: { ...messagesEn },
  ru: { ...messagesRu },
}

const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  const defaultLng = LngStorage.get();
  
  const [locale, setLocale] = useState(defaultLng || 'en')
  const [messages, setMessages] = useState(menuMessages[defaultLng] || menuMessages['en']);
  const [availableLanguages, setAvailableLanguages] = useState(['en']);

  useEffect(async () => {
    const {data} = await http.get('/languages');
    setAvailableLanguages(data)
  }, []);

  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang] || menuMessages['en'])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage, availableLanguages }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale={defaultLng || 'en'}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
