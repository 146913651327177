// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import layout from './layout'
import navbar from './navbar'
import ordersHistory from './orders-history'
import order from './order';
import staticPages from './static-pages';
import publicPages from './public-pages';
import notifications from './notifications';
import faqPageReducer from './faq-page';
import settings from './settings';

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  ordersHistory,
  order,
  staticPages,
  notifications,
  faqPageReducer,
  publicPages,
  settings,
})

export default rootReducer
