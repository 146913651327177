import axios from 'axios';
import { toast } from 'react-toastify';
import { basePublicURL } from '@configs/constants';


const ERROR_DISPLAY_TIMEOUT = 3000;
let isErrorDisplayed = false;

const notifyError = (error) => toast.error(error, { hideProgressBar: true, position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 })


const http = axios.create({
  withCredentials: false,
  baseURL: basePublicURL,
  headers: {'Content-Type': 'application/json'},
});

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error && error.response === undefined) {
      if (!isErrorDisplayed) {
        notifyError(error.response?.data?.message || 'Something went wrong :(');
        isErrorDisplayed = true;
        setTimeout(() => isErrorDisplayed = false, ERROR_DISPLAY_TIMEOUT);
      }
    }
    
    if (error?.response?.status === 500) {
      if (!isErrorDisplayed) {
        notifyError(error.response?.data?.message || 'Server error');
        isErrorDisplayed = true;
        setTimeout(() => isErrorDisplayed = false, ERROR_DISPLAY_TIMEOUT);
      }
    }
    return Promise.reject(error)
  },
)

export default http;
